import { Component } from "react";

//library imports
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';

//components
import HeaderCommon from '../components/HeaderCommon'
import MapComponent from "../components/MapComponent";
import SpinnerLoader from '../components/SpinnerLoader'

//api
import { getOrderSummary, getCountries, postLocalItems, recieveToken, addNewAddress, placeOrder, existingUser, addNonExistingUser, getSupportData, getCompanyInfo, sendWhatsapp, createRazorPayOrder, getOrderWithOrderId } from "../services/services";

//constants
import * as language from '../constants/languages'

//icons
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Ionicon from '../assets/svg/caret-down-outline.svg'
import Closeicon from '../assets/svg/x-circle.svg'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MapIcon from "../assets/svg/mapIcon.svg"
import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg'
import VerifiedIcon from '../assets/svg/verified.svg'
//styles
import '../styles/ShareCatalogue.css'
import OrderSucess from "../components/OrderSuccess";

class ShareCataloguePage extends Component {
    constructor(props) {
        super(props)
        this.mediaQueryList = window.matchMedia("(min-width: 992px)");
        this.state = {
            currencySymbol: "",
            whatsAppNo: "",
            address: null,
            comments: '',
            countriesList: {},
            currentLongitude: null,
            currentLatitude: null,
            isLoading: true,
            landmark: '',
            locationModal: false,
            name: '',
            number: '',
            openCountryPicker: false,
            userCountry: null,
            noaddressid: false,
            selectedCountry: {
                active: true,
                countryCode: null,
                countryFlag: null,
                countryId: null,
                countryName: null,
                countryPhoneCode: null
            },
            countryFlag: '',
            message: '',
            snackopen: false,
            priceDetails: {},
            orderPlacedModal: false,
            whatsApptoken: '',
            whatsInstanceid: '',
            isLargeDisplay: this.mediaQueryList.matches,
            encryptedQr: undefined,
            isPending: false
        };
        this.checkDevice = this.checkDevice.bind(this)
        this.orderPlacedData = undefined
        this.sortCartItems();
        this.sortedCartItems = []
        this.checkInterval = undefined
        this.token = undefined
    }

    async getCompanyInfo() {
        await getCompanyInfo(language.companyidValue).then((response) => {
            // console.log(response, 'companyInfo');
            if (response.status === 'success') {
                this.setState({
                    currencySymbol: response.data.currencySymbol
                })
            }
        }).catch((error) => {
            // console.log(error, 'company info error');
        })
    }

    // get the location details
    async getLocation() {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if (result.state === "denied") {
                this.setState({
                    message: 'Please enable location',
                    snackopen: true
                })
            }
        })
        await navigator.geolocation.getCurrentPosition(
            (position) => {
                const currentLongitude = position.coords.longitude;
                const currentLatitude = position.coords.latitude;
                this.setState({
                    currentLongitude: (currentLongitude),
                    currentLatitude: (currentLatitude),
                    locationModal: (false),
                })
            },
            (error) => console.log(error, 'Error in getting location'),
            { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        );
    }

    async sortCartItems() {
        const getCart = localStorage.getItem('cartItems')
        const cart = JSON.parse(getCart);
        for (let i = 0; i < cart?.customerCartItems?.length; i++) {
            this.sortedCartItems[i] = `\n${i + 1}. \*${cart?.customerCartItems[i]?.product?.productName}\*   *   ${cart?.customerCartItems[i]?.qty} ${cart?.customerCartItems[i]?.product?.unit == '-- None --' ? "" : cart?.customerCartItems[i]?.product?.unit}`
        }
    }

    async getSupportInfoData() {
        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {
                this.setState({
                    supportNumber: (supportData.data.contactNos),
                    whatsAppNo: (supportData.data.whatsApp)
                })
            }
        })
    }

    //get countries list
    async getCountryData() {
        await getCountries(language.companyidValue).then((countryResponse) => {
            if (countryResponse.status == "success") {
                this.setState({
                    countriesList: (countryResponse.data)
                })
                if (countryResponse.data != null && countryResponse.data.length > 0) {
                    if (this.state.userCountry == null && countryResponse.data.length == 1) {
                        this.setState({
                            selectedCountry: (countryResponse.data[0])
                        })
                    } else {
                        const country = this.state.countriesList.find(data => data.countryCode === this.state.userCountry);
                        if (country) {
                            this.setState({
                                isLoading: (false),
                                selectedCountry: (country)
                            })
                        } else {
                            this.setState({
                                isLoading: (false),
                                selectedCountry: (countryResponse.data[0])
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    isLoading: false
                })
            }
        }).catch((error) => { })

    }

    //function for country picker list
    renerderCounties(countryData, i) {
        const details = countryData;
        let selectedCountryvar = async (country) => {
            this.setState({
                selectedCountry: (country),
                openCountryPicker: (!this.state.openCountryPicker)
            })
        }

        return (
            <div
                key={i}
                disabled={this.state.selectedCountry?.countryId === details.countryId}
                style={{
                    display: 'flex',
                    marginTop: 15,
                    flexDirection: 'row',
                    borderBottomWidth: 1,
                    padding: 10,
                    alignItems: 'center',
                    borderBottomColor: this.state.selectedCountry?.countryId === details.countryId ? 'rgba(0,0,0,0.05)' : 'rgba(0,0,0,0.12)',
                    backgroundColor: this.state.selectedCountry?.countryId === details.countryId ? 'rgba(0,0,0,0.05)' : 'auto'
                }}
                onClick={() => selectedCountryvar(details)}
            >
                <div style={{ marginRight: 30 }} >
                    <img
                        src={details.countryFlag}
                        style={{ width: 30, height: 22 }}
                    />
                </div>
                <div >
                    <p
                        style={{ fontSize: 18, margin: 0 }}
                    >
                        ({details.countryPhoneCode}) {details.countryName}
                    </p>
                </div>
            </div>
        )
    }


    // get the location
    async selectLocation() {
        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if (result.state === "denied") {
                this.setState({
                    message: 'Please enable location',
                    snackopen: true,
                    locationModal: false
                })
                return
            }
        })
        await navigator.geolocation.getCurrentPosition(
            (position) => {
                const currentLongitude = position.coords.longitude;
                const currentLatitude = position.coords.latitude;
                this.setState({
                    currentLongitude: (currentLongitude),
                    currentLatitude: (currentLatitude),
                    locationModal: !this.state.locationModal,
                })
            },
            (error) => {
                console.log(error, 'Error in getting location')
                this.setState({
                    message: 'Error in getting location',
                    snackopen: true,
                    locationModal: false
                })
            },
            { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
        )
    }

    // get cart count
    getCartCount = () => {
        const cart = localStorage.getItem('cartItems');
        if (!cart) {
            return true
        }
    }

    shareCatalogue = async () => {
        // let setArray = [['name', this.state.name ? this.state.name : ''], ['address', this.state.address ? String(this.state.address) : ''], ['number', this.state.number ? this.state.number : ''], ['comments', this.state.comments ? this.state.comments : ''], ['landmark', this.state.landmark ? this.state.landmark : '']]
        localStorage.setItem('name', this.state.name ? this.state.name : '')
        localStorage.setItem('address', this.state.address ? String(this.state.address) : '')
        localStorage.setItem('number', this.state.number ? this.state.number : '')
        localStorage.setItem('comments', this.state.comments ? this.state.comments : '')
        localStorage.setItem('landmark', this.state.landmark ? this.state.landmark : '')
        if (this.getCartCount()) {
            this.setState({
                message: 'Your cart is empty',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        if (this.state.number == null || this.state.number == undefined) {
            this.setState({
                message: 'Please enter a valid mobile number',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (/^[0-9]*$/.test(this.state.number) == false) {
            this.setState({
                message: 'Please enter a valid mobile number',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (this.state.name == null || this.state.name == undefined) {
            this.setState({
                message: 'Please enter your name',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (this.state.name.trim() == '') {
            this.setState({
                message: 'Please enter your name',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (this.state.address == null || this.state.address == undefined) {
            this.setState({
                message: 'Please enter your address',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (String(this.state.address).trim().length == 0) {
            this.setState({
                message: 'Please enter your address',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (this.state.landmark == null || this.state.landmark == undefined) {
            this.setState({
                message: 'Please enter a Landmark',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else if (this.state.landmark.trim().length == 0) {
            this.setState({
                message: 'Please enter a Landmark',
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }
        else {
            this.setState({
                isLoading: (true)
            })
            this.ExistingUserCheck()
        }
    }

    async ExistingUserCheck() {
        if (this.state.selectedCountry?.countryId == null) {
            this.setState({
                message: language.countryChooseLabel,
                snackopen: true,
                isLoading: false
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return
        }

        const mobileNo = this.state.selectedCountry.countryPhoneCode + this.state.number;
        // console.log(mobileNo)
        await existingUser(mobileNo).then((existingUserResponse) => {
            if (existingUserResponse.status == "success") {
                this.setState({
                    userData: (existingUserResponse)
                })
                setTimeout(() => {
                    this.recieveToken(mobileNo)
                }, 500)
            }
            else if (existingUserResponse.status == "failed") {
                this.addNonExistingUser(mobileNo)
            }
        }).catch(() => {
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            this.setState({
                message: 'An error occurred, Please try again Later...',
                snackopen: true,
                isLoading: false
            })
            //this.props.navigation(-1)
        })
    }

    async addNonExistingUser(mobileNo) {
        await addNonExistingUser(language.companyidValue,
            this.state.name,
            mobileNo,
            '',
            this.state.number,
            '').then((registerData) => {
                // console.log(registerData)
                if (registerData.status == "success") {
                    this.setState({
                        userData: (registerData)

                    })

                    setTimeout(() => {

                        this.recieveToken(mobileNo)
                    }, 500)

                }
                else if (registerData.status == "failed" && registerData.message == "Mobile Number Already Registered") {
                    this.setState({
                        userData: (registerData),
                        noaddressid: true
                    })

                    setTimeout(() => {

                        this.recieveToken(mobileNo)
                    }, 500)
                }
                else {
                    const event = new CustomEvent('catalogueShare');
                    window.dispatchEvent(event)
                    this.setState({
                        message: 'An error occured, Please try again later...',
                        snackopen: true,
                        isLoading: false
                    })
                    //this.props.navigation(-1)
                }

            }).catch(() => {
                const event = new CustomEvent('catalogueShare');
                window.dispatchEvent(event)
                this.setState({
                    message: 'An error occured, Please try again later...',
                    snackopen: true,
                    isLoading: false
                })
                //this.props.navigation(-1)
            })
    }


    // login user
    async recieveToken(mobile) {
        // console.log(mobile)
        if (this.state.selectedCountry?.countryId == null) {
            this.setState({
                message: language.countryChooseLabel,
                snackopen: true
            })
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            return;
        }
        await recieveToken(language.companyidValue,
            mobile,
            this.state.number).then((loginData) => {
                if (loginData.error) {
                    this.setState({
                        isLoading: (false),
                        massage: loginData.error,
                        snackopen: true
                    })
                    const event = new CustomEvent('catalogueShare');
                    window.dispatchEvent(event)
                    return
                }
                else {
                    this.token = loginData.access_token
                    this.postLocalItems(loginData.access_token);
                }
            })
            .catch((error) => {
                const event = new CustomEvent('catalogueShare');
                window.dispatchEvent(event)
                this.setState({
                    message: 'An error occured, Please try again later...',
                    snackopen: true,
                    isLoading: false
                })
                //this.props.navigation(-1)
            })


    }


    async addressDataSubmit(token, localpostdata) {
        // await getAddressInfo(language.companyidValue, token).then((address)=>{
        //     console.log(address)
        // })

        this.AddAddress(token, localpostdata)


    }

    async AddAddress(token, localpostdata) {
        // console.log(localpostdata)
        try {
            var mobileNo = this.state.selectedCountry.countryPhoneCode + this.state.number;
            let data = {
                CustomerId: this.state.noaddressid ? localpostdata.customerId : this.state.userData.data.customerId,
                CustomerAddressId: this.state.noaddressid ? localpostdata.deliveryAddressId : this.state.userData.data.customerAddressId,
                Name: this.state.name,
                MobileNo1: mobileNo,
                HouseNo: this.state.address,
                Area: '',
                City: this.state.city,
                State: '',
                Country: '',
                Pincode: '',
                Landmark: this.state.landmark,
                Default: true,
                Latitude: this.state.currentLatitude,
                Longitude: this.state.currentLongitude,
                type: 1
            }

            await addNewAddress(language.companyidValue, data, token).then((editAddress) => {
                this.getOrderSummary(token)
            }).catch((e) => {
                const event = new CustomEvent('catalogueShare');
                window.dispatchEvent(event)
                // console.log(e)
                this.setState({
                    message: 'An error occured, Please try again later...',
                    snackopen: true,
                    isLoading: false
                })
                //this.props.navigation(-1)
            })
        } catch (e) {
            const event = new CustomEvent('catalogueShare');
            window.dispatchEvent(event)
            this.setState({
                message: 'An error occured, Please try again later...',
                snackopen: true,
                isLoading: false
            })
            //this.props.navigation(-1)
        }
    }
    async getOrderSummary(token) {

        // debugger
        const branchId = localStorage.getItem('storeId');
        let discountAmount
        await getOrderSummary(language.companyidValue, branchId, token).then((orderSummary) => {
            if (orderSummary.status == "success") {
                this.setState({
                    productItems: orderSummary.data.orderItems,
                    deliveyAddress: orderSummary.data.orderAddresses[0],
                    priceDetails: orderSummary.data
                })
                // let taxAmount = this.state.priceDetails.netTaxAmount;
                if (this.state.priceDetails.billDiscount != null) {
                    discountAmount = this.state.priceDetails.discountAmount;
                } else {
                    discountAmount = this.state.priceDetails.discountAmount +
                        this.state.priceDetails.billDiscount;
                }
                let keys = ['discountType', 'discountRefId'];
                let filtered = orderSummary.data.orderDiscounts.filter(
                    (s => o =>
                        (k => !s.has(k) && s.add(k))
                            (keys.map(k => o[k]).join('|'))
                    )
                        (new Set)
                );
                let stockNotAvailabeleCount = orderSummary.data.orderItems.filter(item => item.isStockAvailable == false).length;

                let stockNotAvailable = (stockNotAvailabeleCount > 0 ? true : false)
                let filteredOffers = filtered
                // this.props.navigation(`/PaymentsPage`, {
                //     state: {
                //         orderSummary: orderSummary.data,
                //         token: token,
                //         memberName: this.state.name,
                //         number: this.state.number,
                //         countryPhoneCode: this.state.selectedCountry.countryPhoneCode,
                //         currentLatitude: this.state.currentLatitude,
                //         currentLongitude: this.state.currentLongitude
                //     }
                // })
                this.payWithRazorPay(orderSummary.data, token)

            } else {
                this.setState({
                    isLoading: (false)

                })

            }
        })
            .catch((error) => {
                const event = new CustomEvent('catalogueShare');
                window.dispatchEvent(event)
                this.setState({
                    message: 'An error occurred, Please try again Later',
                    snackopen: true,
                    isLoading: false
                })
                //this.props.navigation(-1)
            })
    }



    paymentSuccess = async (successData) => {
        console.log(successData, 'successData')
        const order = {
            OrderPaymentId: successData.razorpay_payment_id,
            PaymentRef: successData.razorpay_order_id,
            PaymentMessage: null,
            providers: [],
            OrderId: this.state.priceDetails.orderId,
            PaymentAmount: this.state.priceDetails.totalAmount,
            Status: this.state.priceDetails.status,
            PaymentProviderType: 'MANUAL',
            PaymentProviderName: null,
            PaymentMethod: null,
            PaymentProviderId: null,
            PaymentDate: new Date(),
            OrderChanel: 'App',
            TimeSlotId: 0,
        }
        this.orderPlacement(order, 3, 2000);
    }



    async orderPlacement(order, retries, delay) {

        let branchId = localStorage.getItem('storeId');

        await placeOrder(language.companyidValue, order, branchId, this.token).then((placeOrder) => {
            if (placeOrder.status == 'success') {
                this.setState({
                    isLoading: (false),
                    message: placeOrder.message,
                    snackopen: true,
                    orderPlacedModal: true
                })
                const event = new CustomEvent('catalogueShare');
                const productEvent = new CustomEvent('productQtyChange');
                this.getOrderStatus()
                const msg = encodeURIComponent(`\*Your Order Recieved\*  \n\n\*Order No:\* ${this.orderPlacedData?.orderNo ? this.orderPlacedData?.orderNo : ''} \n\n \*Product Details:\* \n${this.sortedCartItems.join('\n')}\n\n\*Total:\*   ${this.state.priceDetails ? this.state.currencySymbol : ''} ${this.state.priceDetails ? this.state.priceDetails.totalAmount : ''}\n\n\*Customer Details:\*\n\n${this.state.name ? this.state.name : ''}\n${this.state.address == '' ? this.state.automaticAddress : this.state.address}\n\n\*Contact Number:\* ${this.state.number ? this.state.number : ''}\n\n\*Land Mark\*: ${this.state.landmark ? this.state.landmark : ''}\n\n\*Comments:\* ${this.state.comments ? this.state.comments : ''} \n\nFor any queries please contact: ${this.state.supportNumber}\n\n\*Location:\* https://maps.google.com/?q=${this.state.currentLatitude},${this.state.currentLongitude}`)
                window.open(`whatsapp://send?text=${msg}&phone=${this.state.whatsAppNo}`)
                // this.sendOrderToWhatsapp(msg)
                localStorage.removeItem('cartItems')
                window.dispatchEvent(event)
                window.dispatchEvent(productEvent)




            } else {
                throw new Error

                //this.props.navigation(-1)
            }
        })
            .catch((error) => {

                if (retries > 0) {
                    console.log(`Retrying... (${retries} retries left)`);

                    // Retry the `placeOrder` function after a delay
                    setTimeout(() => {
                        this.orderPlacement(order, retries - 1, delay * 2); // Exponentially increase the delay
                    }, delay);
                } else {
                    this.setState({
                        isPending: true
                    })

                    // // After all retries fail, show error message
                    // setOrderFailedModal(true);
                    // Toast.show('Failed to place the order after multiple attempts. Please try again later.', { duration: Toast.durations.LONG });
                    // EventRegister.emit('enableBtn');
                }
                console.log(error)
                // Toast.show('An error occurred')
                // EventRegister.emit('enableBtn')
                // navigation.goBack()
            })


    }
    async getOrderStatus() {
        try {
            const orderStatusResponse = await getOrderWithOrderId(language.companyidValue, this.state.priceDetails.orderId)
            console.log(orderStatusResponse, "orderStatusResponse")
            if (orderStatusResponse.status === "success") {
                clearInterval(this.checkInterval)
                this.orderPlacedData = orderStatusResponse.data[0]
                this.setState({
                    isLoading: false,
                    encryptedQr: orderStatusResponse.message,
                    isPending: false,
                    orderPlacedModal: true,
                    orderFailedModal: false
                })
                const event = new CustomEvent('catalogueShare');
                const productEvent = new CustomEvent('productQtyChange');
                window.dispatchEvent(event)
                window.dispatchEvent(productEvent)
                const msg = encodeURIComponent(`\*Your Order Recieved\*  \n\n\*Order No:\* ${this.orderPlacedData?.orderNo ? this.orderPlacedData?.orderNo : ''} \n\n \*Product Details:\* \n${this.sortedCartItems.join('\n')}\n\n\*Total:\*   ${this.state.priceDetails ? this.state.currencySymbol : ''} ${this.state.priceDetails ? this.state.priceDetails.totalAmount : ''}\n\n\*Customer Details:\*\n\n${this.state.name ? this.state.name : ''}\n${this.state.address == '' ? this.state.automaticAddress : this.state.address}\n\n\*Contact Number:\* ${this.state.number ? this.state.number : ''}\n\n\*Land Mark\*: ${this.state.landmark ? this.state.landmark : ''}\n\n\*Comments:\* ${this.state.comments ? this.state.comments : ''} \n\nFor any queries please contact: ${this.state.supportNumber}\n\n\*Location:\* https://maps.google.com/?q=${this.state.currentLatitude},${this.state.currentLongitude}`)
                window.open(`whatsapp://send?text=${msg}&phone=${this.state.whatsAppNo}`)
            }
        } catch (error) {

        }
    }

    //initialize razorpay
    async payWithRazorPay(orderSummaryData, token) {
        try {
            const createOrderResponse = await createRazorPayOrder(orderSummaryData.orderId, orderSummaryData.totalAmount * 100, token)
            console.log(createOrderResponse, orderSummaryData.totalAmount * 100 === createOrderResponse.data.amount, "createOrderResponse")
            if (createOrderResponse.status === "success" && (orderSummaryData.totalAmount * 100) === createOrderResponse.data.amount) {
                var options = {
                    "key": "rzp_live_b5Ha12UXRBaWx1", // Enter the Key ID generated from the Dashboard
                    "amount": String(createOrderResponse.data.amount), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    "currency": "INR",
                    "name": 'Naipunnya Institute of Management and Information Technology', //your business name
                    "order_id": createOrderResponse.data.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    "handler": this.paymentSuccess,
                    "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
                        "name": this.state.name, //your customer's name
                        "email": "gaurav.kumar@example.com",
                        "contact": this.state.number  //Provide the customer's phone number for better conversion rates 
                    },
                    "modal": {
                        "ondismiss": () => {
                            // Handle the modal dismissal (cancellation)
                            this.setState({
                                message: 'Payment was cancelled. Please try again if you wish to complete the transaction.',
                                snackopen: true,
                                isLoading: false
                            });
                        }
                    }

                };
                var rzp1 = new window.Razorpay(options);
                rzp1.on('payment.failed', function (response) {
                    const event = new CustomEvent('catalogueShare');
                    window.dispatchEvent(event)
                    this.setState({
                        message: 'Your payment failed',
                        snackopen: true,
                        isLoading: false
                    })
                });
                rzp1.on('payment.cancelled', function (response) {
                    const event = new CustomEvent('catalogueShare');
                    window.dispatchEvent(event)
                    this.setState({
                        message: 'Your payment was failed',
                        snackopen: true,
                        isLoading: false
                    })
                });
                rzp1.open();

            }
            else {
                const event = new CustomEvent('catalogueShare');
                window.dispatchEvent(event)
                this.setState({
                    message: 'Something went wrong, please try again',
                    snackopen: true,
                    isLoading: false
                })
            }
        } catch (error) {

        }


    }

    async sortCartItems() {
        const getCart = await localStorage.getItem('cartItems')
        const cart = JSON.parse(getCart);
        // console.log(cart)
        for (let i = 0; i < cart?.customerCartItems?.length; i++) {
            this.sortedCartItems[i] = `\n${i + 1}. \*${cart?.customerCartItems[i]?.product?.productName}\*   x   ${cart?.customerCartItems[i]?.qty} ${cart?.customerCartItems[i]?.product?.unit == '-- None --' ? "" : cart?.customerCartItems[i]?.product?.unit}`
        }

    }

    // post the local items
    async postLocalItems(token) {
        let branchId = localStorage.getItem('storeId');

        let cart = localStorage.getItem('cartItems');
        let cartItems = JSON.parse(cart);
        if (cartItems != null) {
            var localItems = [];
            for (let i of cartItems.customerCartItems) {
                localItems = [...localItems,
                {
                    productId: i.product.productId,
                    variantId: i.variantId,
                    qty: i.qty
                }
                ]
            }
            await postLocalItems(language.companyidValue, branchId, localItems, token).then((localPost) => {
                if (localPost.status == "success") {
                    this.addressDataSubmit(
                        token, localPost.data
                    )
                } else {
                    const event = new CustomEvent('catalogueShare');
                    window.dispatchEvent(event)
                    this.setState({
                        message: 'An error occurred, Please try again Later',
                        snackopen: true,
                        isLoading: false
                    })
                    //this.props.navigation(-1)
                }

            }).catch((error) => {
                const event = new CustomEvent('catalogueShare');
                window.dispatchEvent(event)
                this.setState({
                    message: 'An error occurred, Please try again Later',
                    snackopen: true,
                    isLoading: false
                })

                //this.props.navigation(-1)
            })
        }

    }

    async getSupportInfoData() {
        await getSupportData(language.companyidValue).then((supportData) => {
            if (supportData.status === 'success') {
                this.setState({
                    supportNumber: (supportData.data.contactNos),
                    whatsAppNo: (supportData.data.catalogueShareMobile),
                    whatsApptoken: supportData.data.whatsAppAccessToken,
                    whatsInstanceid: supportData.data.whatsAppInstanceID
                })
                // console.log(supportData)
            }
        })
    }

    //getdetsils from storage
    async getDetails() {
        let getArray = ['name', 'address', 'number', 'comments', 'landmark']
        const result = getArray.map((key) => localStorage.getItem(key))
        this.setState({
            name: (result[0]),
            address: (result[1]),
            number: (result[2]),
            comments: (result[3]),
            landmark: (result[4]),
            isLoading: false
        })

    }
    checkDevice(mql) {
        this.setState({
            isLargeDisplay: mql.matches,
        })
    }
    componentDidMount() {
        if (this.mediaQueryList.addEventListener) {
            this.mediaQueryList.addEventListener("change", this.checkDevice)
        }
        else {
            this.mediaQueryList.addListener(this.checkDevice)
        }
        window.addEventListener('sharecatalogue', this.shareCatalogue)
        this.getDetails()
        this.getLocation()
        this.getSupportInfoData()
        this.getCompanyInfo()
        this.getCountryData()

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isPending && !prevState.isPending) {
            this.checkInterval = setInterval(() => {
                this.getOrderStatus();
            }, 5000);
        }
        if (!this.state.isPending && prevState.isPending) {
            // Clear interval if isPending becomes false
            clearInterval(this.checkInterval);
            this.checkInterval = null;
        }
    }

    componentWillUnmount() {
        window.removeEventListener('sharecatalogue', this.shareCatalogue)
        if (this.mediaQueryList.addEventListener) {
            this.mediaQueryList.removeEventListener("change", this.checkDevice)
        }
        else {
            this.mediaQueryList.removeListener(this.checkDevice)
        }
    }


    async existingUserdata() {
        var mobileNo = this.state.selectedCountry.countryPhoneCode + this.state.number;
        await existingUser(mobileNo).then((existingUserResponse) => {
            // console.log(existingUserResponse)
            if (existingUserResponse.status == "success") {
                this.setState({

                    name: existingUserResponse.data.name,
                    address: existingUserResponse.data.houseNo,
                    landmark: existingUserResponse.data.landmark
                })


            }
            else if (existingUserResponse.status == "failed") {

            }
        }).catch(() => {
            this.setState({
                message: 'An error occurred, contact data was not fetched ',
                snackopen: true
            })


        })
    }
    onSnackClose = () => {
        this.setState({
            snackopen: false
        })
    }
    keydown(e) {
        if (e.key === "Enter") {
            this.existingUserdata()
        }
    }
    async sendOrderToWhatsapp(message) {
        const mobileNumber = this.state.selectedCountry.countryPhoneCode + this.state.number
        await sendWhatsapp(mobileNumber, message, this.state.whatsInstanceid, this.state.whatsApptoken).then((response) => {
            // console.log(response)
        }).catch((error) => {
            this.setState({

                message: 'Whatsapp message was not delivered',
                snackopen: true,

            })
        })

    }
    action = (
        <>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({
                    snackopen: false
                })}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    location(latitude, longitude) {
        this.setState({
            locationModal: (false),


            currentLatitude: (latitude),
            currentLongitude: (longitude),
        })
    }



    render() {
        return (
            <div style={{display: "flex", flexDirection: "column",flex:1}}>
                {
                    this.state.isLoading ?
                        <SpinnerLoader />
                        :
                        <div>
                            <HeaderCommon />
                            <div style={{ padding: 15 }}>
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <p style={{

                                            fontFamily: 'BeVietnamPro-Regular',
                                            fontSize: 14, marginTop: 0,
                                        }}>Code*</p>
                                        {
                                            this.state.selectedCountry.countryFlag == null ?
                                                < div style={{ display: 'flex', border: '1px solid #707070', alignItems: 'center', width: 80, height: 45, marginTop: 10, justifyContent: 'center', }} onClick={() => this.setState({ openCountryPicker: true })}>
                                                    <div >
                                                        <PhoneAndroidIcon />
                                                    </div>
                                                    <div >
                                                        <img src={Ionicon} height={10} />
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ display: 'flex', border: '1px solid #707070', alignItems: 'center', width: 80, height: 45, marginTop: 10, justifyContent: 'space-evenly', borderRadius: 3 }} onClick={() => this.setState({ openCountryPicker: true })}>
                                                    <img
                                                        src={this.state.selectedCountry.countryFlag}
                                                        width={15} height={12}
                                                    />
                                                    <div >
                                                        <p
                                                            style={{
                                                                fontFamily: 'BeVietnamPro-Regular', margin: 0
                                                            }}
                                                        >
                                                            {this.state.selectedCountry.countryPhoneCode}
                                                        </p>
                                                    </div>
                                                    <div >
                                                        <img src={Ionicon} height={10} />
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                    <div style={{ marginLeft: 30, width: '100%', }}>
                                        <p style={{
                                            marginTop: 20,
                                            fontFamily: 'BeVietnamPro-Regular',
                                            fontSize: 14, marginTop: 0,
                                        }}>Mobile Number*</p>

                                        <input type="number" style={{ display: 'flex', border: '1px solid #707070', alignItems: 'center', width: 70, height: 45, marginTop: 10, justifyContent: 'center', width: '100%', outline: 'none', borderRadius: 3, padding: 10, }} onChange={(event) => this.setState({ number: event.target.value })} value={this.state.number} onBlur={() => this.existingUserdata()} onKeyDown={(e) => this.keydown(e)} />




                                    </div>
                                </div>
                                <div className="ShareCatalogueField">
                                    <p >
                                        Name*
                                    </p>
                                    <input style={{ width: '100%', border: '1px solid #707070', height: 45, outline: 'none', borderRadius: 3, padding: 10 }} value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} />

                                </div>
                                <div className="ShareCatalogueField">
                                    <p>
                                        Address*
                                    </p>
                                    <textarea type="text" style={{ width: '100%', border: '1px solid #707070', outline: 'none', borderRadius: 3, padding: 10 }} value={this.state.address ? this.state.address : ''} onChange={(event) => this.setState({ address: event.target.value })} rows={5} />

                                </div>
                                <div className="ShareCatalogueField">
                                    <p>
                                        Land Mark*
                                    </p>
                                    <input style={{ width: '100%', border: '1px solid #707070', height: 45, outline: 'none', borderRadius: 3, padding: 10 }} value={this.state.landmark} onChange={(event) => this.setState({ landmark: event.target.value })} />

                                </div>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="ShareCatalogueField" style={{ flex: 1 }}>
                                        <p>
                                            Comments
                                        </p>
                                        <textarea style={{ width: '100%', border: '1px solid #707070', outline: 'none', borderRadius: 3, padding: 10 }} value={this.state.comments} onChange={(event) => this.setState({ comments: event.target.value })} />

                                    </div >
                                    <img src={MapIcon} style={{ alignSelf: 'flex-end', padding: 15 }} onClick={() => this.selectLocation()} />
                                </div>
                            </div>


                            {

                                this.state.locationModal &&
                                <MapComponent
                                    currentLatitude={this.state.currentLatitude}
                                    currentLongitude={this.state.currentLongitude}
                                    location={(latitude, longitude) => this.location(latitude, longitude)}
                                />

                            }

                        </div>
                }
                {
                    this.state.isLargeDisplay &&

                    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }} >
                        <p className='order-page-large-cancel-button' onClick={() => this.props.navigation('/Cart')}>Cancel</p>
                        <p className='cart-page-large-order-button' onClick={this.shareCatalogue}>
                            Place Order
                        </p>
                    </div>


                }

                <Modal
                    open={this.state.openCountryPicker}
                    onClose={() => this.setState({ openCountryPicker: (!this.state.openCountryPicker) })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ alignItems: 'center',  justifyContent: 'center' }}
                >
                    <div style={{ backgroundColor: 'white', padding: 30, width: '90%' }} >
                        <div style={{ display: 'flex', marginBottom: 15 }}>
                            <div>
                                <img src={Closeicon} onClick={() => this.setState({ openCountryPicker: (!this.state.openCountryPicker) })} />
                            </div>
                            <div style={{ justifyContent: 'center' }}>
                                <p
                                    style={{

                                        fontSize: 18,
                                        marginLeft: 10
                                    }}
                                >
                                    {(language.countryChooseLabel)}
                                </p>
                            </div>
                        </div>
                        {
                            Object.keys(this.state.countriesList).length > 0 ?
                                this.state.countriesList?.map((countrydata, i) => (
                                    this.renerderCounties(countrydata, i)
                                )) : null
                        }
                    </div>

                </Modal>

                <Modal
                    open={this.state.orderPlacedModal}
                    onClose={() => this.setState({ orderPlacedModal: (!this.state.orderPlacedModal) })}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flex:1,overflow:"scroll" }}
                >
                    <OrderSucess orderData={this.orderPlacedData} encryptId={this.state.encryptedQr} />
                </Modal>
                <Snackbar
                    open={this.state.snackopen}
                    autoHideDuration={2000}
                    onClose={this.onSnackClose}
                    message={this.state.message}
                    action={this.action}
                />
            </div>
        )
    }


}

export default function (props) {
    const navigation = useNavigate()
    return <ShareCataloguePage {...props} navigation={navigation} />;
}