import React from "react";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
    const navigate = useNavigate();

    return (
        <div >
            
            <div style={{ padding: 15 }}>
                <h1 style={{ fontWeight: 'bold', fontSize: 24, marginVertical: 15 }}>Privacy Policy</h1>
                <p>
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}> Information Collection and Use {"\n\n"} </span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Personal Information:</span> When you place an order, we may collect personal information such as your name, contact information, and payment details for processing orders.{'\n\n'}
                    <br /><br />
                    <span style={{ fontWeight: 'bold' }}> Usage Data:</span> We may collect information on how the application is accessed and used. This may include device information and usage patterns.{'\n\n'}
                    <br /><br />

                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Use of Information{'\n\n'}</span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}> Order Processing:</span> Personal information is used to process orders, facilitate payments, and provide the requested services.{'\n\n'}
                    <br /><br />
                    <span style={{ fontWeight: 'bold' }}>Improvement of Services:</span> Collected data helps us analyze trends, track user interactions, and improve our application’s functionality and user experience.{'\n\n'}
                    <br /><br />

                    <span style={{ fontWeight: 'bold', fontSize: 16 }}> Information Sharing{'\n\n'}</span>
                    <br />
                    <span style={{ fontWeight: 'bold' }}> Third-Party Services: </span>We may use third-party services for payment processing and analytics. These services have their own privacy policies governing the use of your information.{'\n\n'}
                    <br /><br />
                    <span style={{ fontWeight: 'bold' }}> Legal Requirements:</span> We may disclose personal information in response to legal requests or to comply with applicable laws and regulations.{'\n\n'}
                    <br /><br />

                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Data Security{"\n\n"}</span>
                    <br />
                    We implement security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is entirely secure. We cannot guarantee absolute security.{'\n\n'}
                    <br /><br />

                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Data Retention{"\n\n"}</span>
                    <br />
                    We retain personal information for as long as necessary to provide services and fulfill the purposes outlined in this Privacy Policy. Users may request deletion of their accounts and associated data.{'\n\n'}
                    <br /><br />

                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Children’s Privacy{"\n\n"}</span>
                    <br />
                    Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If you become aware that a child has provided us with personal information, please contact us to have it removed.{'\n\n'}
                    <br /><br />
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Changes to This Privacy Policy{"\n\n"}</span>
                    <br />
                    We reserve the right to update or modify this Privacy Policy at any time. Users will be notified of any changes via the application or through provided contact information.{'\n\n'}
                    <br /><br />

                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>Contact Us{"\n\n"}</span>
                    <br />
                    If you have any questions or concerns about this Privacy Policy, please contact us at jeevani@naipunnya.ac.in
                </p>
            </div>
        </div>
    );
}
